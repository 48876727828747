<template>
  <div>
    <v-text-field dense filled :value="inputVal" @input="setPrice">
    </v-text-field>
  </div>
</template>

<script>
export default {
  name: "InputPrice",
  props: {
    value: {
      type: String,
      default: "0",
    },
  },
  data() {
    return {};
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
        this.$emit("setPriceUnFormat", this.unformatPrice(val));
      },
    },
  },
  // watch: {
  //   value(val) {
  //     console.log(val);
  //   }
  // },
  methods: {
    formatPrice(value) {
      let stringMoney = value.toString();
      stringMoney.replace(".", "");
      let money = parseInt(stringMoney.replace(/\D/g, ""), 10);
      if (Number.isNaN(money)) return "0";
      return money.toLocaleString();
    },
    unformatPrice(value) {
      return value.toString().replaceAll(",", "");
    },

    setPrice(value) {
      this.inputVal = this.formatPrice(value);
    },
  },
};
</script>

<style scoped></style>
